import { getSession, providers, signIn } from 'next-auth/client';
import Page from '../components/Page';
import Server from '../server/Server';
import Links from '../navigation/Links';
import styles from '../assets/pages/login-page.module.scss';
import { HtmlButton } from '../components/HtmlButton';
import PageSection from '../components/PageSection';
import GoogleLogo from '../../public/icons/google.svg';
import { withRouter } from 'next/router';
import Meta from '../util/Meta';
import AuthProvider from '../data/AuthProvider';
import Util from '../util';

const Icons = {
	[AuthProvider.GOOGLE]: <GoogleLogo style={{ width: 20, height: 20 }} />,
};

function SignIn({ providers, router }) {
	const signInWithProvider = (providerId) => {
		if (!providerId) providerId = AuthProvider.GOOGLE;

		const callbackUrl = router.query?.from
			? `${process.env.NEXT_PUBLIC_WEBSITE_URL}${router.query?.from}`
			: null;
		signIn(providerId, { callbackUrl });
	};

	return (
		<Page padding={false} className={styles.loginPage} meta={Meta.Login}>
			<PageSection
				fullScreen
				className={`${styles.landingBanner} small-padding`}
			>
				<div className={styles.landingBannerLeft}>
					<h1 className={`${styles.loginTitle} left-aligned`}>
						To see your shoutout sign in to swanvo below
					</h1>
					<br />
					<br />
					{Object.values(providers).map((provider) => (
						<div key={provider.name} className={styles.loginButtons}>
							<HtmlButton
								type="filled"
								color="purple"
								className="provider-button square"
								size="small"
								onClick={() => signInWithProvider(provider.id)}
							>
								{Icons[provider.id]} <span>Sign in with {provider.name}</span>
							</HtmlButton>
						</div>
					))}
				</div>
				<div className={styles.bannerImage}>
					<img
						className={styles.desktopBanner}
						src="/images/top-fold-banner2.png"
						alt="swan-desktop"
					/>
					<img
						className={styles.mobileBanner}
						src="/images/top-fold-banner2.png"
						alt="swan-mobile"
					/>
				</div>
			</PageSection>

			<style global jsx>{`
				header.ant-layout-header:not(.scrolled) {
					background: var(--background-color-landing);
				}
			`}</style>
		</Page>
	);
}

export default withRouter(SignIn);

export const getServerSideProps = async (context) => {
	const { query } = context;

	const session = await getSession(context);
	if (session)
		return Server.redirect(
			query?.from || Util.withQuery(Links.DASHBOARD, query)
		);

	return {
		props: {
			providers: await providers(context),
		},
	};
};
